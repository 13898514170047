import {ChangeDetectorRef, Component, OnInit, TemplateRef} from '@angular/core';
import {
  ModalDismissReasons,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CountryModel} from '../../../../lyautey/model/country.model';
import {GenreModel} from '../../../../lyautey/model/genre.model';
import {NiveauModel} from '../../../../lyautey/model/niveau.model';
import {ProfessionConjointModel} from '../../../../lyautey/model/professionConjoint.model';
import {PersonneModel} from '../../../../lyautey/model/personnel.model';
import {EnfantEtablissementModel} from '../../../../lyautey/model/enfantEtablissement.model';
import {SituationFamilialeModel} from '../../../../lyautey/model/situation-familiale.model';
import {Acharge} from '../../../../lyautey/shared/commons';
import {ActivatedRoute} from '@angular/router';
import {PersonnelService} from '../../../../lyautey/services/personnel.service';
import {ConjointService} from '../../../../lyautey/services/conjoint.service';
import {EnfantService} from '../../../../lyautey/services/enfant.service';
import {CompteBancaireService} from '../../../../lyautey/services/compte-bancaire.service';
import {DiplomeService} from '../../../../lyautey/services/diplome.service';
import {AdresseService} from '../../../../lyautey/services/adresse.service';
import {CountryService} from '../../../../lyautey/services/country.service';
import {GenreService} from '../../../../lyautey/services/genre.service';
import {NiveauService} from '../../../../lyautey/services/niveau.service';
import {NgbDateMomentParserFormatter} from '../../../../lyautey/shared/NgbDateMomentParserFormatter';
import {EnfantEtablissementService} from '../../../../lyautey/services/enfant-etablissement.service';
import {ProfessionConjointService} from '../../../../lyautey/services/profession-conjoint.service';
import {SituationFamilialeService} from '../../../../lyautey/services/situation-familiale.service';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {ConjointModel} from '../../../../lyautey/model/conjoint.model';
import {DiplomeModel} from '../../../../lyautey/model/diplome.model';
import {CompteBancaireModel} from '../../../../lyautey/model/compte-bancaire.model';
import {EmailDomainValidators} from '../../../../lyautey/shared/emailDomain.validators';
import {EnfantModel} from '../../../../lyautey/model/enfant.model';
import {AdresseModel} from '../../../../lyautey/model/adresse.model';
import {CustomDatepickerI18n, I18n} from '../../../../lyautey/shared/CustomDatepickerI18n';
import {DatePipe} from '@angular/common';
import {TypeNiveauService} from '../../../../lyautey/services/type-niveau.service';
import {TypeNiveauModel} from '../../../../lyautey/model/typeNiveau.model';
import {NbDialogService} from '@nebular/theme';
import Swal from 'sweetalert2';
import {RedirectPage} from '../../../../lyautey/shared/redirectPage';
import * as moment from 'moment';
import {CustomAdapter} from '../../../../lyautey/shared/CustomAdapter';
import {CustomDateParserFormatter} from '../../../../lyautey/shared/CustomDateParserFormatter';
import {DocumentService} from '../../../../lyautey/services/document.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AppAuthGuard} from '../../../../lyautey/services/app-auth-guard.service';
import {NbAclService} from '@nebular/security';
import {CanComponentDeactivate} from '../../../../lyautey/guards/can-deactivate.guard';


@Component({
  selector: 'ngx-fiche',
  templateUrl: './fiche.component.html',
  styleUrls: ['./fiche.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    DatePipe,
    {
      provide: NgbDateMomentParserFormatter,
      useFactory: () => new NgbDateMomentParserFormatter('YYYY-MM-DD'),
    },
  ],
})
export class FicheComponent implements OnInit, CanComponentDeactivate {
  action: string = 'add';


  personnelPhoto: SafeUrl = 'assets/images/avatar.jpg';
  submitted: boolean = false;
  photoTypeDocumentId: number = 17;
  // @ts-ignore
  formatter = (x: { result: any }) => x.nomFrFr;
  // @ts-ignore
  formatterPerson = (x: { result: any }) => x.nom + ' ' + x.prenom + ' [' + x.id + ']';

  searching = false;
  searchFailed = false;
  model: NgbDateStruct;
  personneForm: FormGroup;
  countries: Observable<CountryModel[]>;
  genres: Observable<GenreModel[]>;
  niveaux: Observable<NiveauModel[]>;
  typeNiveaux: Observable<TypeNiveauModel[]>;
  professions: Observable<ProfessionConjointModel[]>;
  situationFamiliales: Observable<SituationFamilialeModel[]>;
  etablissements: Observable<EnfantEtablissementModel[]>;
  personnes: Observable<PersonneModel[]>;
  matricule: string = '';
  phoneNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  years: any [] = [...Array(2024 - 1900 + 1).keys()].map(i => 2024 - i);

  acharges: Acharge[] = [
    {value: false, viewValue: 'Non'},
    {value: true, viewValue: 'Oui'},
  ];
  validDomains: string[] = process.env.NG_APP_VALID_DOMAINS.split(',');
  validAefeDomains: string[] = process.env.NG_APP_VALID_AEFE_DOMAINS.split(',');
  validationMessages = {
    'emailDeux': {
      'required': 'Email professionel est obligatore.',
      'emailDomain':
          `Email professionel doit se terminer par [${process.env.NG_APP_VALID_DOMAINS}]`,
    },
    'emailAefe': {
      'required': 'Email aefe est obligatore.',
      'emailDomain': `Email professionel doit se terminer par [${process.env.NG_APP_VALID_AEFE_DOMAINS}]`,
    },
    'matricule': {
      'required': 'Champs obligatore.',
    },
    'genreId': {
      'required': 'Champs obligatore.',
    },
    'situationFamilialeId': {
      'required': 'Champs obligatore.',
    },
    'nom': {
      'required': 'Champs obligatore.',
    },
    'prenom': {
      'required': 'Champs obligatore.',
    },
    'dateNaissance': {
      'required': 'Champs obligatore.',
    },
    'lieuNaissance': {
      'required': 'Champs obligatore.',
    },
    'paysNaissance': {
      'required': 'Champs obligatore.',
    },
    'ancienLieuNaissance': {
      'required': 'Champs obligatore.',
    },
    'numeroCarteNationale': {
      'required': 'Champs obligatore.',
    },
    'email': {
      'required': 'Champs obligatore.',
    },
    'nomUsuel': {
      'required': 'Champs obligatore.',
    },
    'prenomDeux': {
      'required': 'Champs obligatore.',
    },
    'prenomTrois': {
      'required': 'Champs obligatore.',
    },
    'numeroCnss': {
      'required': 'Champs obligatore.',
    },
    'numeroCfe': {
      'required': 'Champs obligatore.',
    },
    'numeroCimr': {
      'required': 'Champs obligatore.',
    },
    'adresseContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'codePostalContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'emailContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'mobileContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'nomContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'paysContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'prenomContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'villeContactUrgence': {
      'required': 'Champs obligatore.',
    },
    'situationFamiliale': {
      'required': 'Champs obligatore.',
    },
    'genre': {
      'required': 'Champs obligatore.',
    },
    'niveau': {
      'required': 'Champs obligatore.',
    },
    'nationalite': {
      'required': 'Champs obligatore.',
    },
    'cnssActivated': {
      'required': 'Champs obligatore.',
    },
    'cfeActivated': {
      'required': 'Champs obligatore.',
    },
    'cimrActivated': {
      'required': 'Champs obligatore.',
    },
  };

  formErrors = {
    matricule: '',
    genreId: '',
    situationFamilialeId: '',
    nom: '',
    prenom: '',
    dateNaissance: '',
    lieuNaissance: '',
    paysNaissance: '',
    numeroCarteNationale: '',
    dateValiditeCarteNationale: '',
    email: '',
    nomUsuel: '',
    prenomDeux: '',
    prenomTrois: '',
    numeroCnss: '',
    numeroCfe: '',
    numeroCimr: '',
    numeroCnops: '',
    numeroMgen: '',
    numeroMgpap: '',
    emailDeux: '',
    emailAefe: '',
    adresseContactUrgence: '',
    codePostalContactUrgence: '',
    emailContactUrgence: '',
    mobileContactUrgence: '',
    nomContactUrgence: '',
    paysContactUrgence: '',
    prenomContactUrgence: '',
    villeContactUrgence: '',
    situationFamiliale: '',
    genre: '',
    niveau: '',
    nationalite: '',
    cnssActivated: '',
    cfeActivated: '',
    cimrActivated: '',
    cnopsActivated: '',
    mgenActivated: '',
    mgpapActivated: '',
    nomMage: '',
  };
  closeResult: string;
  showAncienLieuNaissance: boolean = true;
  private blob: Blob;
  private employeeId: number;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private redirectPage: RedirectPage,
              private personnelService: PersonnelService,
              private conjointService: ConjointService,
              private enfantService: EnfantService,
              private compteBancaireService: CompteBancaireService,
              private diplomeService: DiplomeService,
              private adresseService: AdresseService,
              private countryService: CountryService,
              private genreService: GenreService,
              private niveauService: NiveauService,
              private typeNiveauService: TypeNiveauService,
              private situationFamilialeService: SituationFamilialeService,
              private professionConjointService: ProfessionConjointService,
              private enfantEtablissementService: EnfantEtablissementService,
              private documentsService: DocumentService,
              private sanitizer: DomSanitizer,
              private cdr: ChangeDetectorRef,
              private dialogService: NbDialogService,
              public appAuthGuard: AppAuthGuard,
              public nbAclService: NbAclService,
  ) {
  }


  canDeactivate() {
    if (this.personneForm.dirty) {
      return confirm('Vous avez des modifications non enregistrées ! Êtes-vous sûr de vouloir partir ?');
    }
    return true;
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  countrySearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.countryService.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })),
      ),
      tap(() => this.searching = false),
    )

  personSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.personnelService.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })),
      ),
      tap(() => this.searching = false),
    )

  viewPhoto(documentId: number, fileName: string) {
    this.documentsService.downloadFile(documentId).subscribe(data => {
      if (data !== undefined) {
        this.blob = new Blob([data], {type: 'application/octet-stream'});
        // @ts-ignore
        const photoUrl = window.URL.createObjectURL(data);
        this.personnelPhoto = this.sanitizer.bypassSecurityTrustUrl(photoUrl);
      }
    });
  }

  ngOnInit() {
    this.countryService.search('ma');
    this.countries = this.countryService.getAll();
    this.genres = this.genreService.getAll();
    this.situationFamiliales = this.situationFamilialeService.getAll();
    this.niveaux = this.niveauService.getAll();
    this.typeNiveaux = this.typeNiveauService.getAll();
    this.professions = this.professionConjointService.getAll();
    this.etablissements = this.enfantEtablissementService.getAll();
    this.personnes = this.personnelService.getPersonnes();
    this.personneForm = this.fb.group({
      matricule : [null],
      genre : ['', [Validators.required]],
      situationFamiliale: ['', [Validators.required]],
      nom : ['', [Validators.required]],
      prenom : ['', [Validators.required]],
      dateNaissance : ['', [Validators.required]],
      lieuNaissance : ['', [Validators.required]],
      paysNaissance : ['', [Validators.required]],
      ancienLieuNaissance : [''],
      numeroCarteNationale: [''],
      dateValiditeCarteNationale: [null],
      email: ['', Validators.email],
      nomUsuel : ['' , [Validators.required]],
      nomMage : [''],
      prenomDeux : [null],
      prenomTrois : [null],
      numeroCnss: ['', [Validators.maxLength(9), Validators.minLength(9)]],
      numeroCfe: [''],
      numeroCimr: [''],
      numeroCnops: [''],
      numeroMgen: [''],
      numeroMgpap: [''],
      emailDeux: ['', [EmailDomainValidators.emailDomain(this.validDomains)]],
      emailAefe: ['', [EmailDomainValidators.emailDomain(this.validAefeDomains)]],
      adresseContactUrgence: [''],
      codePostalContactUrgence: [''],
      emailContactUrgence: ['', [Validators.email]],
      mobileContactUrgence: ['', [Validators.maxLength(10), Validators.pattern(this.phoneNumberPattern)]],
      nomContactUrgence: [''],
      paysContactUrgence: [''],
      prenomContactUrgence: [''],
      villeContactUrgence: [''],
      typeNiveau : ['', [Validators.required]],
      nationalite: [''],
      cnssActivated: [''],
      cfeActivated: [''],
      cimrActivated: [''],
      cnopsActivated: [''],
      mgenActivated: [''],
      mgpapActivated: [''],
      enfants: this.fb.array([ this.addEnfantFormGroup()]),
      conjoints: this.fb.array([ this.addConjointFormGroup()]),
      adresses: this.fb.array([ this.addAdresseFormGroup()]),
      diplomes: this.fb.array([ this.addDiplomeFormGroup()]),
      compteBancaires: this.fb.array([ this.addCompteBancaireFormGroup()]),
    });


    this.personneForm.valueChanges.subscribe((data) => {
      this.logValidationErrors(this.personneForm);
    });

    this.route.parent.paramMap.subscribe(params => {
      const id: number = Number(params.get('id'));
      if (id) {
        this.getPersonnel(params.get('id'));
        this.action = 'edit';
        this.employeeId = id;
      }
    });
  }

  private getPersonnel(id) {
    this.personnelService.getPhoto(id, this.photoTypeDocumentId).subscribe((data: any) => {
      if (data !== undefined && Number(data.id) > 0 && data.file !== undefined) {
        this.viewPhoto(data.id, data.file);
      }
    });
    this.personnelService.getSituationFamiliale(id)
      .subscribe((data: any) => {
        this.personneForm.patchValue({
          situationFamiliale : data.id,
        });
      });
    this.personnelService.getNationalites(id)
      .subscribe((data: any) => {
        this.personneForm.patchValue({
          nationalite : data._embedded.countries,
        });
      });
    this.conjointService.getByPersonId(id)
      .subscribe((data: any) => {
        this.personneForm.setControl('conjoints', this.setExistingConjoints(data._embedded.conjoints));
        this.patchConjointPersonne(data._embedded.conjoints);
      });
    this.enfantService.getByPersonId(id)
      .subscribe((data: any) => {
        this.personneForm.setControl('enfants', this.setExistingEnfants(data._embedded.enfants));
        this.patchEnfantEtablissement(data._embedded.enfants);
        this.patchEnfantResponsableDeux(data._embedded.enfants);
      });
    this.compteBancaireService.getByPersonId(id)
      .subscribe((data: any) => {
        this.personneForm.setControl('compteBancaires',
          this.setExistingCompteBancaires(data._embedded.compteBancaires));
      });
    this.diplomeService.getByPersonId(id)
      .subscribe((data: any) => {
        this.personneForm.setControl('diplomes', this.setExistingDiplomes(data._embedded.diplomes));
      });
    this.adresseService.getByPersonId(id)
      .subscribe((data: any) => {
        this.personneForm.setControl('adresses', this.setExistingAdresses(data._embedded.adresses));
      });
    this.personnelService.getTypeNiveau(id)
      .subscribe((data: any) => {
        if (data != null && data !== undefined && data.id != null) {
          this.personneForm.patchValue({
            typeNiveau : data.id,
          });
        }
      });
    this.personnelService.getGenre(id)
      .subscribe((data: any) => {
        this.personneForm.patchValue({
          genre : data.id,
        });
      });
    this.personnelService.getPersonnel(id)
      .subscribe(
        (personneModel: PersonneModel) => {
          this.personnelService.getCountryByUrl(personneModel._links.paysNaissance.href)
            .subscribe((data: any) => {
              if (data !== undefined && Number(data.id) > 0 ) {
                this.personneForm.patchValue({
                  paysNaissance : data,
                });
              }
            });
          this.personnelService.getCountryByUrl(personneModel._links.paysContactUrgence.href)
            .subscribe((data: any) => {
              if (data !== undefined && Number(data.id) > 0 ) {
                this.personneForm.patchValue({
                  paysContactUrgence: data,
                });
              }
            });
          this.matricule = '[ ' + personneModel.nomUsuel + ' ' + personneModel.prenom +  ' ]';
          this.editPersonnel(personneModel);
        },
        (err: any) => {},
      );
  }

  editPersonnel(personneModel: PersonneModel) {
    this.personneForm.patchValue({
      id : personneModel.id,
      matricule : personneModel.matricule,
      nom : personneModel.nom,
      nomUsuel : personneModel.nomUsuel,
      nomMage : personneModel.nomMage,
      prenom : personneModel.prenom,
      prenomDeux : personneModel.prenomDeux,
      prenomTrois : personneModel.prenomTrois,
      dateNaissance : personneModel.dateNaissance,
      lieuNaissance : personneModel.lieuNaissance,
      ancienLieuNaissance : personneModel.ancienLieuNaissance,
      numeroCarteNationale: personneModel.numeroCarteNationale,
      dateValiditeCarteNationale: personneModel.dateValiditeCarteNationale,
      numeroCnss: personneModel.numeroCnss,
      numeroCfe: personneModel.numeroCfe,
      numeroCimr: personneModel.numeroCimr,
      cnssActivated: personneModel.cnssActivated,
      cfeActivated: personneModel.cfeActivated,
      cimrActivated: personneModel.cimrActivated,
      numeroCnops: personneModel.numeroCnops,
      numeroMgen: personneModel.numeroMgen,
      numeroMgpap: personneModel.numeroMgpap,

      cnopsActivated: personneModel.cnopsActivated,
      mgenActivated: personneModel.mgenActivated,
      mgpapActivated: personneModel.mgpapActivated,
      email: personneModel.email,
      emailDeux: personneModel.emailDeux,
      emailAefe: personneModel.emailAefe,
      // nationalite: personneModel.nationalites,
      adresseContactUrgence: personneModel.adresseContactUrgence,
      codePostalContactUrgence: personneModel.codePostalContactUrgence,
      emailContactUrgence: personneModel.emailContactUrgence,
      mobileContactUrgence: personneModel.mobileContactUrgence,
      nomContactUrgence: personneModel.nomContactUrgence,

      prenomContactUrgence: personneModel.prenomContactUrgence,
      villeContactUrgence: personneModel.villeContactUrgence,

    });
    if (personneModel.lieuNaissance) {
      this.personneForm.removeControl('ancienLieuNaissance');
      this.showAncienLieuNaissance = false;
    }
  }


  selectedItem(item) {
    this.personneForm.get('paysNaissance').setValue(item.item);
  }
  selectedAdresseItem(item, index: number) {
    (<FormArray>this.personneForm.controls['adresses']).at(index).get('country').setValue(item.item);
  }
  selectedConjointItem(item, index: number) {
    (<FormArray>this.personneForm.controls['conjoints']).at(index).get('professionMatricule').setValue(item.item.id);
  }

  selectedResponsableDeuxItem(item, index: number) {
    (<FormArray>this.personneForm.controls['enfants']).at(index).get('responsableDeux').setValue(item.item.id);
  }

  selectedCompteBancaireItem(item, index: number) {

    (<FormArray>this.personneForm.controls['compteBancaires']).at(index).get('country').setValue(item.item);
  }

  extractDate(date: string): NgbDate {
    if (date !== null && date.length > 0) {
      const dateArray = date.split('-');
      return new NgbDate(parseInt(dateArray[0], 10), parseInt(dateArray[1], 10), parseInt(dateArray[2], 10));
    }
    return new NgbDate(0, 0, 0);
  }


  onFormSubmit() {
    this.cdr.detectChanges();
    if (this.personneForm.invalid) {
      return;
    }
      Swal.fire({
        title: 'Modification fiche personnel',
        text: 'Etes vous sur de vouloir apporter ces modifications?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, je confirme',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          this.route.parent.paramMap.subscribe(params => {
            const id: number = Number(params.get('id'));
            if (id > 0) {
              this.personnelService.updatePersonne(id, this.personneForm)
                .subscribe((person: any) => {
                    if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'create', 'enfant')) {
                      this.enfantService.addToPerson(id, this.personneForm);
                    }
                    if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'create', 'conjoint')) {
                      this.conjointService.addToPerson(id, this.personneForm);
                    }
                    if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'create', 'adresse')) {
                      this.adresseService.addToPerson(id, this.personneForm);
                    }
                    if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'create', 'comptebancaire')) {
                      this.compteBancaireService.addToPerson(id, this.personneForm);
                    }
                    if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'create', 'diplome')) {
                      this.diplomeService.addToPerson(id, this.personneForm);
                    }
                    this.submitted = true;
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Votre modification a été bien enregistrée.`,
                    }).then(() => this.redirectPage.refreshPersonnel({action: 'fiche', key: person.id}));
                  }, (err: any) => {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: `Echec lors de la modifcation.`,
                    });
                  },
                );
            } else {
              this.personnelService.addPersonne(this.personneForm)
                .subscribe((person: any) => {
                    this.submitted = true;
                    this.conjointService.addToPerson(person.id, this.personneForm);
                    this.enfantService.addToPerson(person.id, this.personneForm);
                    this.adresseService.addToPerson(person.id, this.personneForm);
                    this.compteBancaireService.addToPerson(person.id, this.personneForm);
                    this.diplomeService.addToPerson(person.id, this.personneForm);
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: `Votre modification a été bien enregistrée.`,
                    }).then(() => this.redirectPage.refreshPersonnel({action: 'fiche', key: person.id}));
                  }, (err: any) => {});
            }
          });
        }
      });
  }
  onChildDateChange(dt: any, index: number) {
  }
  onCompteBancaireDateChange(dt: any, index: number) {
  }
  openConjoint(dialog: TemplateRef<any>, id: number) {
    this.personnelService.getPersonnel(id)
      .subscribe(
        (personne: PersonneModel) => {
          const conjoint = {personne: {}, contrat: {
              etabblissement: '',
              statut: '',
              corps: '',
              discipline: '',
              contrat: '',
              rit: '',
              regime: '',
            }};
          conjoint.personne = personne;

          this.personnelService.getContrats(id).subscribe(response => {
            // @ts-ignore
            if ( Number(response._embedded.contrats.length) > 0) {
              // @ts-ignore
              const activeContrat: ContratModel = response._embedded.contrats[0];
              this.personnelService.getByUrl(activeContrat._links.etablissement.href).subscribe(value => {
                // @ts-ignore
                if (value !== undefined && value.description !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.etablissement = value.description;
                }
              });
              this.personnelService.getByUrl(activeContrat._links.typeStatut.href).subscribe(value => {
                if (value !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.statut = value.description;
                }
              });
              this.personnelService.getByUrl(activeContrat._links.typeFonction.href).subscribe(value => {
                if (value !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.fonction = value.description;
                }
              });
              this.personnelService.getByUrl(activeContrat._links.typeCorps.href).subscribe(value => {
                if (value !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.corps = value.description;
                }
              });

              this.personnelService.getByUrl(activeContrat._links.typeDiscipline.href).subscribe(value => {
                if (value !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.discipline = value.description;
                }
              });

              this.personnelService.getByUrl(activeContrat._links.typeContrat.href).subscribe(value => {
                if (value !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.contrat = value.description;
                }
              });

              this.personnelService.getByUrl(activeContrat._links.typeRit.href).subscribe(value => {
                if (value !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.rit = value.description;
                }
              });

              this.personnelService.getByUrl(activeContrat._links.typeRegime.href).subscribe(value => {
                if (value !== undefined) {
                  // @ts-ignore
                  conjoint.contrat.regime = value.description;
                }
              });
            }
          });
          this.dialogService.open(dialog, {context: conjoint});
        },
        (err: any) => {},
      );
  }
  onlyNumberKey($event: KeyboardEvent) {
    // @ts-ignore
    return (event.charCode === 8 || event.charCode === 0) ? null : (event.charCode >= 48 && event.charCode <= 57) ;   // lowercase letters
  }
  onlyNumberAndCharsKey(event: KeyboardEvent): boolean {
    const allowedKeys = /[a-zA-Z0-9]/; // regular expression to match alphanumeric characters

    if (event.key === ' ' || !event.key.match(allowedKeys)) {
      // if the key is space or not an alphanumeric character, prevent it from being typed
      event.preventDefault();
      return false;
    }
  }
  private logKeyValuePairs(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logKeyValuePairs(abstractControl);
      }
    });
  }
  logValidationErrors(group: FormGroup = this.personneForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      this.formErrors[key] = '';
      if (abstractControl) {
        if (!abstractControl.valid) {
          abstractControl.markAsTouched();
        }
      }
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      }
      if (abstractControl instanceof FormArray) {
        for (const control of abstractControl.controls) {
          if (control instanceof FormGroup) {
            this.logValidationErrors(control);
          }
        }
      }
    });
  }
  get conjoints() {
    return this.personneForm.get('conjoints') as FormArray;
  }
  get adresses() {
    return this.personneForm.get('adresses') as FormArray;
  }
  get compteBancaires() {
    return this.personneForm.get('compteBancaires') as FormArray;
  }
  get enfants() {
    return this.personneForm.get('enfants') as FormArray;
  }
  get diplomes() {
    return this.personneForm.get('diplomes') as FormArray;
  }
  addEnfantFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      dateNaissance: ['', [Validators.required]],
      acharge: ['', [Validators.required]],
      scolarise: [''],
      nom: ['', [Validators.required]],
      prenom: ['', [Validators.required]],
      prenomDeux: [null],
      enfantEtablissement: ['', [Validators.required]],
      responsableDeux: [null],

    });
  }
  addAdresseFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      rue: ['', [Validators.required]],
      ville: ['', [Validators.required]],
      country: ['', [Validators.required]],
      codePostal: ['', [Validators.required]],
      telephone: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
      gsm: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
    });
  }
  addConjointFormGroup(): FormGroup {
    return this.fb.group({
      id : [''],
      nomDuConjoint: ['', [Validators.required]],
      nom: ['', [Validators.required]],
      prenom: ['', [Validators.required]],
      profession: ['', [Validators.required]],
      professionMatricule: [null],
    });
  }
  addCompteBancaireFormGroup(): FormGroup {
    return this.fb.group({
      id : [''],
      numeroCompte : ['', [Validators.required]],
      libelleBanque : ['', [Validators.required]],
      adresseBanque : ['', [Validators.required]],
      country : ['', [Validators.required]],
      dateRib : ['', [Validators.required]],
    });
  }
  addDiplomeFormGroup(): FormGroup {
    return this.fb.group({
      id : [''],
      libelle : ['', [Validators.required]],
      annee : ['', [Validators.required]],
      niveau : ['', [Validators.required]],
    });
  }
  deleteEnfant(index) {
    if (this.action === 'add') {
      this.enfants.removeAt(index);
      return;
    } else if ( this.action === 'edit') {
      const id = (<FormArray>this.personneForm.controls['enfants']).at(index).get('id').value;
      if (Number(id) > 0) {
        this.enfantService.delete(id).subscribe((enfant: any) => {
            this.enfants.removeAt(index);
          }, (err: any) => {
          },
        );
      } else {
        this.enfants.removeAt(index);
      }
    }

  }
  deleteAdresse(index) {
    if (this.action === 'add') {
      this.adresses.removeAt(index);
      return;
    } else if ( this.action === 'edit') {
      const id = (<FormArray>this.personneForm.controls['adresses']).at(index).get('id').value;
      if (Number(id) > 0) {
        this.adresseService.delete(id).subscribe((adresse: any) => {
            this.adresses.removeAt(index);
          }, (err: any) => {
          },
        );
      } else {
        this.adresses.removeAt(index);
      }
    }
  }
  beforeDelete(index, type, title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        switch (type) {
          case 'conjoint':
            if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'delete', 'conjoint')) {
              this.deleteConjoint(index);
            }
            break;
          case 'enfant':
            if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'delete', 'enfant')) {
              this.deleteEnfant(index);
            }
            break;
          case 'adresse':
            if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'delete', 'adresse')) {
              this.deleteAdresse(index);
            }
            break;
          case 'compteBancaire':
            if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'delete', 'comptebancaire')) {
              this.deleteCompteBancaire(index);
            }
            break;
          case 'diplome':
            if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'delete', 'diplome')) {
              this.deleteDiplome(index);
            }
            break;
        }
      }
    });
  }
  deleteConjoint(index) {
    if (this.action === 'add') {
      this.conjoints.removeAt(index);
      Swal.fire(
        'Suppression de conjoint',
        'Conjoint vient d\'être supprimé',
        'success',
      );
      return;
    } else if ( this.action === 'edit') {
      const id = (<FormArray>this.personneForm.controls['conjoints']).at(index).get('id').value;
      if (Number(id) > 0) {
        this.conjointService.delete(id).subscribe((conjoint: any) => {
            this.conjoints.removeAt(index);
          Swal.fire(
            'Suppression de conjoint',
            'Conjoint vient d\'être supprimé',
            'success',
          );
          }, (err: any) => {
          },
        );
      } else {
        this.conjoints.removeAt(index);
        Swal.fire(
          'Suppression de conjoint',
          'Conjoint vient d\'être supprimé',
          'success',
        );
      }
    }

  }
  deleteCompteBancaire(index) {
    if (this.action === 'add') {
      this.compteBancaires.removeAt(index);
      return;
    }  else if ( this.action === 'edit') {
      const id = (<FormArray>this.personneForm.controls['compteBancaires']).at(index).get('id').value;
      if (Number(id) > 0) {
        this.compteBancaireService.delete(id).subscribe((item: any) => {
            this.compteBancaires.removeAt(index);
          }, (err: any) => {
          },
        );
      } else {
        this.compteBancaires.removeAt(index);
      }
    }

  }
  deleteDiplome(index) {
    if (this.action === 'add') {
      this.diplomes.removeAt(index);
      return;
    }  else if ( this.action === 'edit') {
      const id = (<FormArray>this.personneForm.controls['diplomes']).at(index).get('id').value;
      if (Number(id) > 0) {
        this.diplomeService.delete(id).subscribe((item: any) => {
            this.diplomes.removeAt(index);
          }, (err: any) => {
          },
        );
      } else {
        this.diplomes.removeAt(index);
      }
    }

  }
  private patchEnfantEtablissement(enfants: EnfantModel[]): void {
    let i: number = 0;
    enfants.forEach(enfant => {
      this.enfantEtablissementService.getByUrl(enfant._links.enfantEtablissement.href)
        .subscribe((data: any) => {
          if (data !== undefined && Number(data.id) > 0) {
            (<FormArray>this.personneForm.controls['enfants']).at(i).get('enfantEtablissement').setValue(data.id);
            const scolariseControl = (<FormArray>this.personneForm.controls['enfants']).at(i).get('scolarise');
            if (data.id === 2) {
              scolariseControl.enable();
              scolariseControl.setValidators([Validators.required]);
            } else {
              scolariseControl.disable();
              scolariseControl.clearValidators();
            }
            scolariseControl.updateValueAndValidity();
          }
          i++;
        });
    });
  }
  private patchEnfantResponsableDeux(enfants: EnfantModel[]): void {
    let i: number = 0;
    enfants.forEach(enfant => {
      this.personnelService.getPersoneByUrl(enfant._links.responsableDeux.href)
        .subscribe((data: any) => {
          if (data !== undefined && Number(data.id) > 0) {
            (<FormArray>this.personneForm.controls['enfants'])
              .at(i)
              .get('responsableDeux').setValue(data);
            i++;
          }
        });
    });
  }
  private setExistingEnfants(enfants: EnfantModel[]): FormArray {
    const formArray = new FormArray([]);
    enfants.forEach(enfant => {
      formArray.push(
        this.fb.group({
          id: enfant.id,
          dateNaissance: enfant.dateNaissance,
          acharge: enfant.acharge,
          scolarise: enfant.scolarise,
          nom: enfant.nom,
          prenom: enfant.prenom,
          prenomDeux: enfant.prenomDeux,
          responsableDeux: enfant.responsableDeux,
          enfantEtablissement: enfant.enfantEtablissement,
        }),
      );
    });
    return formArray;
  }
  private setExistingAdresses(adresses: AdresseModel[]): FormArray {
    const formArray = new FormArray([]);
    adresses.forEach(adresse => {
      this.personnelService.getCountryByUrl(adresse._links.country.href)
        .subscribe((data: any) => {
          formArray.push(
            this.fb.group({
              id: adresse.id,
              rue: adresse.rue,
              ville: adresse.ville,
              country: data,
              codePostal: adresse.codePostal,
              telephone: adresse.telephone,
              gsm: adresse.gsm,
            }),
          );
        });
    });

    return formArray;
  }
  private setExistingDiplomes(diplomes: DiplomeModel[]): FormArray {
    const formArray = new FormArray([]);
    diplomes.forEach(diplome => {
      this.diplomeService.getNiveauByUrl(diplome._links.niveau.href)
        .subscribe((niveau: NiveauModel) => {
          formArray.push(
            this.fb.group({
              id: diplome.id,
              libelle: diplome.libelle,
              annee: diplome.annee,
              niveau: niveau.id,
            }),
          );
      });

    });
    return formArray;
  }
  private patchConjointPersonne(conjoints: ConjointModel[]): void {
    let i: number = 0;
    let j: number = 0;
    conjoints.forEach(conjoint => {
      this.personnelService.getProfessionByUrl(conjoint._links.profession.href)
        .subscribe((data: any) => {
          if (data !== undefined && Number(data.id) > 0 ) {
            (<FormArray>this.personneForm.controls['conjoints'])
              .at(j)
              .get('profession').setValue(data.id);
            const professionMatriculeControl =
              (<FormArray>this.personneForm.controls['conjoints']).at(j).get('professionMatricule');
            j++;
          }
        });

      this.personnelService.getPersoneByUrl(conjoint._links.professionMatricule.href)
        .subscribe((data: any) => {
          if (data !== undefined && Number(data.id) > 0 ) {
            const professionMatriculeControl =
              (<FormArray>this.personneForm.controls['conjoints']).at(i).get('professionMatricule');
            if ((<FormArray>this.personneForm.controls['conjoints']).at(i).get('profession').value === 1) {
              professionMatriculeControl.setValue(data);
            }
            i++;
          }
        });


    });
  }
  private setExistingConjoints(conjoints: ConjointModel[]): FormArray {
    const formArray = new FormArray([]);
    conjoints.forEach(conjoint => {
      formArray.push(
        this.fb.group({
          id: conjoint.id,
          nomDuConjoint: conjoint.nomDuConjoint,
          nom: conjoint.nom,
          prenom: conjoint.prenom,
          profession: conjoint.profession,
          professionMatricule: conjoint.professionMatricule,
        }),
      );
    });
    return formArray;
  }
  private setExistingCompteBancaires(compteBancaires: CompteBancaireModel[]): FormArray {
    const formArray = new FormArray([]);
    compteBancaires.forEach(compteBancaire => {
      this.personnelService.getCountryByUrl(compteBancaire._links.country.href)
        .subscribe((data: any) => {

          formArray.push(
            this.fb.group({
              id: compteBancaire.id,
              numeroCompte: compteBancaire.numeroCompte,
              libelleBanque: compteBancaire.libelleBanque,
              adresseBanque: compteBancaire.adresseBanque,
              dateRib: compteBancaire.dateRib,
              // @ts-ignore
              country: data,
            }),
          );
        });
    });
    return formArray;
  }
  addConjointButtonClick(): void {
    (<FormArray>this.personneForm.get('conjoints')).push(this.addConjointFormGroup());
  }
  addEnfantButtonClick() {
    (<FormArray>this.personneForm.get('enfants')).push(this.addEnfantFormGroup());
  }
  addAdresseButtonClick() {
    (<FormArray>this.personneForm.get('adresses')).push(this.addAdresseFormGroup());
  }
  addCompteBancaireButtonClick() {
    (<FormArray>this.personneForm.get('compteBancaires')).push(this.addCompteBancaireFormGroup());
  }
  addDiplomeButtonClick() {
    (<FormArray>this.personneForm.get('diplomes')).push(this.addDiplomeFormGroup());
  }
  onCnssActivatedChange(values: any) {
    const numeroCnssControl = this.personneForm.get('numeroCnss');
    if (values.currentTarget.checked) {
      numeroCnssControl.setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9)]);
    } else {
      numeroCnssControl.clearValidators();
    }
    numeroCnssControl.updateValueAndValidity();
  }

  onCimrActivatedChange(values: any) {
    const numeroCimrControl = this.personneForm.get('numeroCimr');
    if (values.currentTarget.checked) {
      numeroCimrControl.setValidators([Validators.required, Validators.minLength(7), Validators.maxLength(13)]);
    } else {
      numeroCimrControl.clearValidators();
    }
    numeroCimrControl.updateValueAndValidity();
  }

  onSocialSecurityActivatedChange(values: any, controlName: string, min: number, max: number, usePattern: boolean) {
    const socialSecurityControl = this.personneForm.get(controlName);
    if (values.currentTarget.checked) {
      socialSecurityControl.setValidators([Validators.required, Validators.minLength(min), Validators.maxLength(max)]);
      if (usePattern) {
        socialSecurityControl.setValidators(
            [Validators.required,
              Validators.minLength(min),
              Validators.maxLength(max),
              Validators.pattern(/^(?:[a-zA-Z0-9]+)?$/)
            ],
        );
      }

    } else {
      socialSecurityControl.clearValidators();
    }
    socialSecurityControl.updateValueAndValidity();
  }

  onCfeActivatedChange(values: any) {
    const numeroCfeControl = this.personneForm.get('numeroCfe');
    if (values.currentTarget.checked) {
      numeroCfeControl.setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(15)]);
    } else {
      numeroCfeControl.clearValidators();
    }
    numeroCfeControl.updateValueAndValidity();
  }

  selectChangeHandler(event: any, index: number) {
    const selectedValue: number =
      Number((<FormArray>this.personneForm.controls['conjoints']).at(index).get('profession').value);
    const professionMatriculeControl =
      (<FormArray>this.personneForm.controls['conjoints']).at(index).get('professionMatricule');
  }
  selectEtablissementHandler($event: Event, index: number) {
    const selectedValue: number =
      Number((<FormArray>this.personneForm.controls['enfants']).at(index).get('enfantEtablissement').value);
    const scolariseControl = (<FormArray>this.personneForm.controls['enfants']).at(index).get('scolarise');
    if (selectedValue === 2) {
      scolariseControl.enable();
      scolariseControl.setValidators([Validators.required]);
    } else {
      scolariseControl.reset();
      scolariseControl.clearValidators();
      scolariseControl.disable();
    }
    scolariseControl.updateValueAndValidity();
  }

  selectedEvent($event: NgbTypeaheadSelectItemEvent) {
  }



  onClose() {
    this.submitted = false;
  }

  certificat(id: number) {
    this.personnelService.downloadCertificat(id).subscribe(data => {
      this.blob = new Blob([data], {type: 'application/octet-stream'});
      // @ts-ignore
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      const now = moment().locale('fr').format();
      link.download = 'certificat-' + now + '.pdf';
      link.click();
    });
  }


}
