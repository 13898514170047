import {NgModule} from '@angular/core';

import {ThemeModule} from '../../@theme/theme.module';
import {PersonnelRoutingModule} from './personnel-routing.module';
import {ListComponent} from './list/list.component';
import {PersonnelComponent} from './personnel.component';
import {
    NbAccordionModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbRouteTabsetModule,
} from '@nebular/theme';
import {DataTablesModule} from 'angular-datatables';
import {NgbDatepickerModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {PersonneComponent} from './personne/personne.component';
import {FicheComponent} from './personne/fiche/fiche.component';
import {FonctionAnnexeComponent} from './personne/fonction-annexe/fonction-annexe.component';
import {ContratComponent} from './personne/contrat/contrat.component';
import {FormationComponent} from './personne/formation/formation.component';
import {AppCommonModule} from '../app-common/app-common.module';
import {InspectionComponent} from './personne/inspection/inspection.component';
import {DocumentsComponent} from './personne/documents/documents.component';
import {CteComponent} from './personne/cte/cte.component';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {MomentModule} from 'ngx-moment';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {EditDocumentComponent} from './personne/documents/edit-document/edit-document.component';
import {ViewPdfComponent} from './personne/documents/view-pdf/view-pdf.component';
import {ViewImageComponent} from './personne/documents/view-image/view-image.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {NbSecurityModule} from '@nebular/security';
import {AlertsComponent} from './alerts/alerts.component';
import {AccidentTravailComponent} from './personne/accident-travail/accident-travail.component';
import {VisiteMedicaleComponent} from './personne/visite-medicale/visite-medicale.component';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {AvancementComponent} from './personne/avancement/avancement.component';
import {AvancementTimelineComponent} from './personne/avancement-timeline/avancement-timeline.component';
import { SuspensionComponent } from './personne/suspension/suspension.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    ListComponent,
    PersonnelComponent,
    FicheComponent,
    PersonneComponent,
    ContratComponent,
    FonctionAnnexeComponent,
    FormationComponent,
    InspectionComponent,
    DocumentsComponent,
    CteComponent,
    EditDocumentComponent,
    ViewPdfComponent,
    ViewImageComponent,
    AlertsComponent,
    AccidentTravailComponent,
    VisiteMedicaleComponent,
    AvancementComponent,
    AvancementTimelineComponent,
    SuspensionComponent,
  ],
    imports: [
        ThemeModule,
        PersonnelRoutingModule,
        NbCardModule,
        DataTablesModule,
        NgbTypeaheadModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbDatepickerModule,
        NbAccordionModule,
        NbRouteTabsetModule,
        NbButtonModule,
        NbIconModule,
        AppCommonModule,
        NbAlertModule,
        AngularMultiSelectModule,
        MomentModule,
        FormsModule,
        PdfViewerModule,
        NgxDocViewerModule,
        NgxMaskModule.forRoot(maskConfig),
        NbSecurityModule,
        ButtonsModule,
    ],
  exports: [
    ViewPdfComponent,
  ],
})
export class PersonnelModule { }
