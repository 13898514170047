
<div *ngFor="let contrat of contrats">
    <nb-card [status]="getCardStatus(contrat.status)" *ngIf="contrat.suspensions.length > 0">
        <nb-card-header>
            <div class="d-flex justify-content-between align-items-center">
                <div>Contrat: {{ contrat.etablissement }} - {{ contrat.fonction }} - {{ contrat.contrat }}</div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">DateDebut</th>
                        <th scope="col">Date de fin</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of contrat.suspensions; let i=index">
                        <td>{{ item.id }} </td>
                        <td>
                            <span style="display: none">{{ item.dateDebut | amDateFormat:'YYYY-MM-DD' }}</span>
                            {{ item.dateDebut | amDateFormat:'DD/MM/YYYY' }}
                        </td>
                        <td>
                            <span style="display: none">{{ item.dateFin | amDateFormat:'YYYY-MM-DD' }}</span>
                            {{ item.dateFin | amDateFormat:'DD/MM/YYYY' }}

                        </td>
                        <td>
                            <div class="form-inline" style="float: right">
                                <div class="btn-group" btnRadioGroup>
                                    <label class="btn btn-danger" style="cursor: pointer" role="button" btnRadio="Left"
                                           *nbIsGranted="['delete', 'avancement']"
                                           (click)="beforeDelete(item.id,'Suppression de suspension', 'Cette action n\'est pas revocable')"
                                           title="Supprimer"><i class="fas fa-trash"></i></label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </nb-card-body>
    </nb-card>
</div>