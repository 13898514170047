import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListComponent} from './list/list.component';
import {PersonnelComponent} from './personnel.component';
import {PersonneComponent} from './personne/personne.component';
import {FicheComponent} from './personne/fiche/fiche.component';
import {ContratComponent} from './personne/contrat/contrat.component';
import {FonctionAnnexeComponent} from './personne/fonction-annexe/fonction-annexe.component';
import {FormationComponent} from './personne/formation/formation.component';
import {DocumentsComponent} from './personne/documents/documents.component';
import {InspectionComponent} from './personne/inspection/inspection.component';
import {CteComponent} from './personne/cte/cte.component';
import {AlertsComponent} from './alerts/alerts.component';
import {AccidentTravailComponent} from './personne/accident-travail/accident-travail.component';
import {VisiteMedicaleComponent} from './personne/visite-medicale/visite-medicale.component';
import {AvancementComponent} from './personne/avancement/avancement.component';
import {CanDeactivateGuard} from '../../lyautey/guards/can-deactivate.guard';
import {SuspensionComponent} from "./personne/suspension/suspension.component";


const routes: Routes = [{
  path: '',
  component: PersonnelComponent,
  children: [
    {
      path: 'list',
      component: ListComponent,
    },
    {
      path: 'alerts',
      component: AlertsComponent,
    },
    {
      path: 'personne',
      component: PersonneComponent,
      children: [
        {
          path: '',
          redirectTo: 'fiche',
          pathMatch: 'full',
        },
        {
          path: 'fiche',
          component: FicheComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'contrat',
          component: ContratComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'fonction-annexe',
          component: FonctionAnnexeComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'formation',
          component: FormationComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'documents',
          component: DocumentsComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'inspection',
          component: InspectionComponent,
        },
        {
          path: 'cte',
          component: CteComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'visite-medicale',
          component: VisiteMedicaleComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'at',
          component: AccidentTravailComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'avancement',
          component: AvancementComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'suspension',
          component: SuspensionComponent,
          canDeactivate: [CanDeactivateGuard],
        },
      ],
    },
    {
      path: 'personne/:id',
      component: PersonneComponent,
      children: [
        {
          path: '',
          redirectTo: 'fiche',
          pathMatch: 'full',
        },
        {
          path: 'fiche',
          component: FicheComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'contrat',
          component: ContratComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'fonction-annexe',
          component: FonctionAnnexeComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'formation',
          component: FormationComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'documents',
          component: DocumentsComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'inspection',
          component: InspectionComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'cte',
          component: CteComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'visite-medicale',
          component: VisiteMedicaleComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'accident-travail',
          component: AccidentTravailComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'avancement',
          component: AvancementComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: 'suspension',
          component: SuspensionComponent,
          canDeactivate: [CanDeactivateGuard],
        },
      ],
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PersonnelRoutingModule { }


