<form [formGroup]='editForm' (ngSubmit)="onFormSubmit()" autocomplete="off">
    <div class="form-row" style="margin-top: 12px" *nbIsGranted="['create', 'contrat']">
        <div class="form-group col-md-10"></div>
        <div class="form-group col-md-2">
            <button nbButton status="success" class="float-right" type="button" (click)="addContratButtonClick()"><i
                    class="fas fa-plus"></i></button>
        </div>
    </div>
    <div formArrayName="contrats" *ngIf="editForm['controls'].contrats['controls'].length>0">
        <div *ngFor="let contrat of editForm['controls'].contrats['controls']; let i=index">
            <div [formGroupName]="i">
                <br *ngIf="i==0"/>
                <nb-card status="{{contrat.get('statusText').value}}">
                    <nb-card-header>
                        Contrat <span class="badge badge-light">N&deg;: {{ contrat.get('id').value }} </span> &nbsp;
                        <span class="badge badge-dark">{{ contrat.get('statusTextValue').value.toUpperCase() }}</span>
                        &nbsp;
                        <span *ngIf="contrat.get('statusMage').value === 'Passif'"
                              class="badge badge-pill badge-danger">MAGE {{ contrat.get('statusMage').value.toUpperCase() }}</span>
                        <span *ngIf="contrat.get('statusMage').value === 'Actif'"
                              class="badge badge-pill badge-success">MAGE {{ contrat.get('statusMage').value.toUpperCase() }}</span>
                        <span *ngIf="contrat.get('statusMage').value === 'Nouveau'"
                              class="badge badge-pill badge-primary">MAGE {{ contrat.get('statusMage').value.toUpperCase() }}</span>
                        <span *ngIf=" contrat.get('regime').value === 10" class="badge badge-secondary">Durée de vacation: {{ vacation[i] }}
                            jour(s) | Total vacation(s): {{ totalVacation }}
                            jour(s)  | Total Heures(s): {{ totalHeuresVacation }} heure(s)</span>
                        <label *ngIf="contrat.get('statut').value === 3 && contrat.get('regime').value === 10;"
                               (click)="certificat(contrat.get('id').value)" style="cursor: pointer; float: right"
                               class="btn btn-primary  " role="button" title="NOTIFICATION DE SUPPLEANCE"> <i
                                class="fas fa-file-pdf"></i></label>
                    </nb-card-header>

                    <nb-card-body>
                        <nb-accordion multi style="margin-top: 20px;">
                            <nb-accordion-item
                                    [expanded]="contrat.get('status').value == 'actif' || new[i] ? true : false"
                                    *ngIf="contrat.get('statut').value !== 1 && contrat.get('poste').value > 0">

                                <nb-accordion-item-header class="nb-accordion-item-header-override">
                                    Informations MAGE
                                </nb-accordion-item-header>

                                <nb-accordion-item-body>

                                    <div class="form-row">

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'etablissementMage' +i">Établissement</label>
                                            <input type="text" class="form-control" placeholder="Établissement"
                                                   formControlName="etablissementMage" [id]="'etablissementMage' +i"
                                                   [readOnly]="true"/>
                                            <input type="hidden" class="form-control" formControlName="poste"
                                                   [id]="'poste' +i" [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'matriculeMage' +i">Matricule</label>
                                            <input type="text" class="form-control" placeholder="Matricule"
                                                   formControlName="matriculeMage" [id]="'matriculeMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'codeMage' +i">Poste</label>
                                            <input type="text" class="form-control" placeholder="Poste"
                                                   formControlName="codeMage" [id]="'codeMage' +i" [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label [attr.for]="'nomMage' +i">Nom</label>
                                            <input type="text" class="form-control" placeholder="Nom"
                                                   formControlName="nomMage" [id]="'nomMage' +i" [readOnly]="true"/>
                                        </div>


                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateNaissanceMage' +i">Date de naissance</label>
                                            <input type="text" class="form-control" placeholder="Date de naissance"
                                                   formControlName="dateNaissanceMage" [id]="'dateNaissanceMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'nationaliteMage' +i">Nationalité</label>
                                            <input type="text" class="form-control" placeholder="Nationalité"
                                                   formControlName="nationaliteMage" [id]="'nationaliteMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'niveauEtudeMage' +i">Niveau d'étude</label>
                                            <input type="text" class="form-control" placeholder="Niveau d'étude"
                                                   formControlName="niveauEtudeMage" [id]="'niveauEtudeMage' +i"
                                                   [readOnly]="true"/>
                                        </div>


                                    </div>


                                    <div class="form-row">

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'contratMage' +i">Contrat </label>
                                            <input type="text" class="form-control" placeholder="Contrat"
                                                   formControlName="contratMage" [id]="'contratMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'fonctionMage' +i">Fonction </label>
                                            <input type="text" class="form-control" placeholder="Fonction"
                                                   formControlName="fonctionMage" [id]="'fonctionMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'disciplineMage' +i">Discipline </label>
                                            <input type="text" class="form-control" placeholder="Discipline"
                                                   formControlName="disciplineMage" [id]="'disciplineMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'disciplineOrigineMage' +i">Discipline d'origine</label>
                                            <input type="text" class="form-control" placeholder="Discipline d'origine"
                                                   formControlName="disciplineOrigineMage"
                                                   [id]="'disciplineOrigineMage' +i" [readOnly]="true"/>
                                        </div>


                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'tnrMage' +i">Titulaire non résident</label>
                                            <input type="checkbox" class="form-control" formControlName="tnrMage"
                                                   [id]="'tnrMage' +i" [readOnly]="true" disabled="disabled"/>
                                        </div>

                                    </div>
                                    <div class="form-row">

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'typeMage' +i">Type </label>
                                            <input type="text" class="form-control" placeholder="Type"
                                                   formControlName="typeMage" [id]="'typeMage' +i" [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateEmbaucheMage' +i">Date d'embauche </label>
                                            <input type="text" class="form-control" placeholder="Date d'embauche "
                                                   formControlName="dateEmbaucheMage" [id]="'dateEmbaucheMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'etpMage' +i">Etp</label>
                                            <input type="text" class="form-control" placeholder="ETP"
                                                   formControlName="etpMage" [id]="'etpMage' +i" [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'tempsMage' +i">Temps</label>
                                            <input type="text" class="form-control" placeholder="Temps"
                                                   formControlName="tempsMage" [id]="'tempsMage' +i" [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'quotiteMage' +i">Quotité</label>
                                            <input type="text" class="form-control" placeholder="Quotité"
                                                   formControlName="quotiteMage" [id]="'quotiteMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'quotiteHebdoMage' +i">Quotité Hebdomadaire</label>
                                            <input type="text" class="form-control" placeholder="Quotité Hebdo"
                                                   formControlName="quotiteHebdoMage" [id]="'quotiteHebdoMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                    </div>


                                </nb-accordion-item-body>

                            </nb-accordion-item>

                            <nb-accordion-item
                                    [expanded]="contrat.get('status').value == 'actif'  || new[i] || contrat.get('regime').value === 10 ? true : false">
                                <nb-accordion-item-header class="nb-accordion-item-header-override">
                                    Informations générales
                                </nb-accordion-item-header>

                                <nb-accordion-item-body>

                                    <div class="form-row">

                                        <div class="form-group col-md-3">
                                            <input type="hidden" class="form-control" formControlName="id"/>
                                            <label [attr.for]="'etablissement' +i">Etablissement</label>
                                            <select class="form-control" formControlName="etablissement"
                                                    id="'etablissement' +i"
                                                    [ngClass]="{'is-invalid': contrat.get('etablissement').invalid && contrat.get('etablissement').touched}"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of (etablissements | async)"
                                                        [ngValue]="item.id ">
                                                    {{ item.description }}
                                                </option>
                                            </select>
                                        </div>


                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'statut' +i">Statut</label>
                                            <select class="form-control" formControlName="statut" id="'statut' +i"
                                                    (change)="statutChangedHandler($event, i)"
                                                    [ngClass]="{'is-invalid': contrat.get('statut').invalid && contrat.get('statut').touched}"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of (statuts | async)" [ngValue]="item.id ">
                                                    {{ item.description }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'regime' +i">Régime</label>
                                            <select class="form-control" formControlName="regime" id="'regime' +i"
                                                    (change)="regimeChangedHandler($event, i)"
                                                    [ngClass]="{'is-invalid': contrat.get('regime').invalid && contrat.get('regime').touched}"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of (regimes[i] | async)" [ngValue]="item.id">
                                                    {{ item.description }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'type' +i">Type</label>
                                            <select class="form-control" formControlName="type" id="'type' +i"
                                                    [ngClass]="{'is-invalid': contrat.get('type').invalid && contrat.get('type').touched}"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    (blur)="logValidationErrors()"
                                                    (change)="regimeTypeChangedHandler($event, i)">
                                                <option *ngFor="let item of (types[i] | async)" [ngValue]="item.id ">
                                                    {{ item.description }}
                                                </option>
                                            </select>

                                        </div>

                                    </div>

                                    <div class="form-row">

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'corps' +i">Corps</label>
                                            <select class="form-control" formControlName="corps" id="'corps' +i"
                                                    [ngClass]="{'is-invalid': contrat.get('corps').invalid && contrat.get('corps').touched}"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of (corps | async)" [ngValue]="item.id ">
                                                    {{ item.description }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'fonction' +i">Fonction</label>
                                            <select class="form-control" formControlName="fonction" id="'fonction' +i"
                                                    (change)="typeFonctionChangedHandler($event, i)"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    [ngClass]="{'is-invalid': contrat.get('fonction').invalid && contrat.get('fonction').touched}"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of (fonctions | async)" [ngValue]="item.id">
                                                    {{ item.description }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-2" *ngIf="contrat.get('fonction').value === 33">
                                            <label [attr.for]="'discipline' +i">Discipline</label>
                                            <select class="form-control" formControlName="discipline"
                                                    id="'discipline' +i"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null">
                                                <option *ngFor="let item of enseignantDisciplines"
                                                        [ngValue]="item.value ">
                                                    {{ item.viewValue }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group"
                                             [ngClass]="(contrat.get('fonction').value === 33) ? 'col-md-2' : 'col-md-3'">
                                            <label [attr.for]="'categorieMage' +i">Catégorie MAGE</label>
                                            <input type="text" class="form-control" placeholder="Catégorie MAGE"
                                                   formControlName="categorieMage" [id]="'categorieMage' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                        <div class="form-group"
                                             [ngClass]="(contrat.get('fonction').value === 33) ? 'col-md-2' : 'col-md-3'">
                                            <label [attr.for]="'categorieRit' +i">Catégorie RIT</label>
                                            <input type="text" class="form-control" placeholder="Catégorie RIT"
                                                   formControlName="categorieRit" [id]="'categorieRit' +i"
                                                   [readOnly]="true"/>
                                        </div>

                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-1">
                                            <label class="control-label" [attr.for]="'status' +i">Etat</label>
                                            <select class="form-control" formControlName="status" id="'status' +i"
                                                    (change)="statusChangedHandler($event, i)"
                                                    [ngClass]="{'is-invalid': contrat.get('status').invalid && contrat.get('status').touched}"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of statutContrats" [ngValue]="item.value ">
                                                    {{ item.viewValue }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateEntreeErc' +i">Date d'entrée dans le pôle</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Date d'entrée dans le pôle"
                                                       formControlName="dateEntreeErc" [id]="'dateEntreeErc' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d7="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateEntreeErc').invalid && contrat.get('dateEntreeErc').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d7.toggle()" type="button" [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container
                                                *ngIf="contrat.get('statut').value === 3 && contrat.get('regime').value === 10; then templateVacataire; else templateOther">
                                        </ng-container>
                                        <div class="form-group col-md-1"
                                             *ngIf="contrat.get('corps').value === 7 || contrat.get('corps').value === 12">
                                            <label class="control-label"
                                                   [attr.for]="'encadrement' +i">Encadrement</label>
                                            <input type="checkbox" class="form-control" formControlName="encadrement"
                                                   [id]="'encadrement' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                            />
                                        </div>

                                        <div class="form-group col-md-2"
                                             *ngIf="contrat.get('statut').value === 3 && (contrat.get('regime').value === 6 || contrat.get('regime').value === 7)">
                                            <label class="control-label" [attr.for]="'disponibilite' +i">Titulaire en
                                                disponibilité</label>
                                            <input type="checkbox" class="form-control" formControlName="disponibilite"
                                                   [id]="'disponibilite' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"/>
                                        </div>

                                        <div class="form-group col-md-2" *ngIf="contrat.get('regime').value === 3">
                                            <label class="control-label" [attr.for]="'rrd' +i">Résident à recrutement
                                                différé</label>
                                            <input type="checkbox" class="form-control" formControlName="rrd"
                                                   [id]="'rrd' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"/>
                                        </div>
                                    </div>
                                    <ng-template #templateOther>
                                        <ng-container [ngSwitch]="contrat.get('status').value">
                                            <ng-container *ngSwitchCase="'actif'">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'suspendu'">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateDebutSuspension' +i">Date début
                                                        suspension</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date début suspension"
                                                               formControlName="dateDebutSuspension"
                                                               [id]="'dateDebutSuspension' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d3="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [disabled]="contrat.get('status').value !== 'suspendu'"
                                                               [ngClass]="{'is-invalid': contrat.get('dateDebutSuspension').invalid && contrat.get('dateDebutSuspension').touched}"
                                                               (blur)="logValidationErrors()"
                                                               (change)="suspensionDatesChangedHandler($event, i)"
                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d3.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateFinSuspension' +i">Date fin 2
                                                        suspension</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date fin suspension"
                                                               formControlName="dateFinSuspension"
                                                               [id]="'dateFinSuspension' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d4="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [disabled]="contrat.get('status').value !== 'suspendu'"
                                                               [ngClass]="{'is-invalid': contrat.get('dateFinSuspension').invalid && contrat.get('dateFinSuspension').touched}"
                                                               (blur)="logValidationErrors()"

                                                               (dateSelect)="suspensionDatesChangedHandler($event, i)"
                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d4.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'remplacant' +i">Remplaçant</label>
                                                    <input type="text" class="form-control" formControlName="remplacant"
                                                           [id]="'remplacant' +i"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [disabled]="contrat.get('status').value !== 'suspendu'"
                                                           [ngbTypeahead]="personSearch" [editable]='false'
                                                           (selectItem)="selectedRemplacant($event, i)"
                                                           placeholder="Remplaçant"
                                                           [resultFormatter]="resultPersonFormatter"
                                                           [inputFormatter]="inputPersonFormatter"/>

                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'radie'">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateSortieErc' +i">Date de sortie de
                                                        L'ERC</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date de sortie de L'ERC"
                                                               formControlName="dateSortieErc" [id]="'dateSortieErc' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d2="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [ngClass]="{'is-invalid': contrat.get('dateSortieErc').invalid && contrat.get('dateSortieErc').touched}"
                                                               (blur)="logValidationErrors()"

                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d2.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'archive'">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateArchivage' +i">Date d'archivage</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date d'archivage"
                                                               formControlName="dateArchivage" [id]="'dateArchivage' +i"
                                                               ngbDatepicker #d1="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [disabled]="contrat.get('status').value !== 'archive'"
                                                               container="body"
                                                               [ngClass]="{'is-invalid': contrat.get('dateArchivage').invalid && contrat.get('dateArchivage').touched}"
                                                               (blur)="logValidationErrors()"

                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d1.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value !== 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                    <ng-template #templateVacataire>
                                        <ng-container [ngSwitch]="contrat.get('status').value">
                                            <ng-container *ngSwitchCase="'actif'">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'suspendu'">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateDebutSuspension' +i">Date début 1
                                                        suspension</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date début suspension"
                                                               formControlName="dateDebutSuspension"
                                                               [id]="'dateDebutSuspension' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d3="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [disabled]="contrat.get('status').value !== 'suspendu'"
                                                               [ngClass]="{'is-invalid': contrat.get('dateDebutSuspension').invalid && contrat.get('dateDebutSuspension').touched}"
                                                               (blur)="logValidationErrors()"
                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d3.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateFinSuspension' +i">Date fin 1
                                                        suspension</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date fin suspension"
                                                               formControlName="dateFinSuspension"
                                                               [id]="'dateFinSuspension' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d4="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [disabled]="contrat.get('status').value !== 'suspendu'"
                                                               [ngClass]="{'is-invalid': contrat.get('dateFinSuspension').invalid && contrat.get('dateFinSuspension').touched}"
                                                               (blur)="logValidationErrors()"
                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d4.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'remplacant' +i">Remplaçant</label>
                                                    <input type="text" class="form-control" formControlName="remplacant"
                                                           [id]="'remplacant' +i"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [disabled]="contrat.get('status').value !== 'suspendu'"
                                                           [ngbTypeahead]="personSearch" [editable]='false'
                                                           (selectItem)="selectedRemplacant($event, i)"
                                                           placeholder="Remplaçant"
                                                           [resultFormatter]="resultPersonFormatter"
                                                           [inputFormatter]="inputPersonFormatter"/>

                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'radie'">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateSortieErc' +i">Date de sortie de
                                                        L'ERC</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date de sortie de L'ERC"
                                                               formControlName="dateSortieErc" [id]="'dateSortieErc' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d2="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [disabled]="contrat.get('status').value !== 'radie'"
                                                               [ngClass]="{'is-invalid': contrat.get('dateSortieErc').invalid && contrat.get('dateSortieErc').touched}"
                                                               (blur)="logValidationErrors()"

                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d2.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'archive'">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'dateArchivage' +i">Date d'archivage</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date d'archivage"
                                                               formControlName="dateArchivage" [id]="'dateArchivage' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               ngbDatepicker #d1="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [disabled]="contrat.get('status').value !== 'archive'"
                                                               container="body"
                                                               [ngClass]="{'is-invalid': contrat.get('dateArchivage').invalid && contrat.get('dateArchivage').touched}"
                                                               (blur)="logValidationErrors()"

                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d1.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateCdd' +i">Date début vacation</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       placeholder="Date début vacation" formControlName="dateCdd"
                                                       [id]="'dateCdd' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d10="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateCdd').invalid && contrat.get('dateCdd').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d10.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateFinCdd' +i">Date fin vacation</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Date fin vacation"
                                                       formControlName="dateFinCdd" [id]="'dateFinCdd' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d4="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateFinCdd').invalid && contrat.get('dateFinCdd').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d4.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'remplacant' +i">Remplaçant</label>
                                            <input type="text" class="form-control" formControlName="remplacant"
                                                   [id]="'remplacant' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' || contrat.get('status').value !== 'suspendu' ? 'disabled' : null"
                                                   [ngbTypeahead]="personSearch" [editable]='false'
                                                   (selectItem)="selectedRemplacant($event, i)"
                                                   placeholder="Remplaçant" [resultFormatter]="resultPersonFormatter"
                                                   [inputFormatter]="inputPersonFormatter"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label [attr.for]="'cddMotif' +i">Motif</label>
                                            <input type="text" class="form-control" placeholder="Motif"
                                                   formControlName="cddMotif" [id]="'cddMotif' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('cddMotif').invalid && contrat.get('cddMotif').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'vacationCategorieHoraire' +i">Catégorie</label>
                                            <select class="form-control" formControlName="vacationCategorieHoraire"
                                                    id="'vacationCategorieHoraire' +i"
                                                    (change)="vacationCategorieHoraireChangedHandler($event, i)"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    [ngClass]="{'is-invalid': contrat.get('vacationCategorieHoraire').invalid && contrat.get('vacationCategorieHoraire').touched}"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of (vacationCategorieHoraires | async)"
                                                        [ngValue]="item.id ">
                                                    {{ item.description }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'cddTauxHoraire' +i">Taux Horaire</label>
                                            <input type="text" class="form-control" [readOnly]="true"
                                                   placeholder="Taux Horaire" formControlName="cddTauxHoraire"
                                                   [id]="'cddTauxHoraire' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('cddTauxHoraire').invalid && contrat.get('cddTauxHoraire').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'cddHeure' +i">Nombre d'heures</label>
                                            <input type="text" class="form-control" placeholder="Nombre d'heures"
                                                   formControlName="cddHeure" [id]="'cddHeure' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('cddHeure').invalid && contrat.get('cddHeure').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'cddMajoration' +i">Majoration</label>
                                            <input type="checkbox" class="form-control" formControlName="cddMajoration"
                                                   [id]="'cddMajoration' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"/>
                                        </div>
                                    </ng-template>
                                </nb-accordion-item-body>
                            </nb-accordion-item>

                            <!-- Titulaire détaché  -->
                            <nb-accordion-item
                                    [expanded]="contrat.get('status').value == 'actif'  || new[i]  ? true : false"
                                    *ngIf="contrat.get('statut').value === 1 && contrat.get('regime').value !== 8">

                                <nb-accordion-item-header class="nb-accordion-item-header-override">
                                    Informations contrat
                                </nb-accordion-item-header>

                                <nb-accordion-item-body>
                                    <div class="form-row">
                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'academie' +i">Académie</label>
                                            <input type="text" class="form-control" placeholder="Académie"
                                                   formControlName="academie" [id]="'academie' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('academie').invalid && contrat.get('academie').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'ministere' +i">Ministère</label>
                                            <input type="text" class="form-control" placeholder="Ministere"
                                                   formControlName="ministere" [id]="'ministere' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('ministere').invalid && contrat.get('ministere').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>


                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'periodeDetachement' +i">Détachement</label>
                                            <input type="text" class="form-control" placeholder="Detachement"
                                                   formControlName="periodeDetachement" [id]="'periodeDetachement' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('periodeDetachement').invalid && contrat.get('periodeDetachement').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'dureeDetachement' +i">Durée Détachement</label>
                                            <select class="form-control" formControlName="dureeDetachement"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    id="'dureeDetachement' +i">
                                                <option *ngFor="let item of dureeDetachement" [ngValue]="item.value ">
                                                    {{ item.viewValue }}
                                                </option>
                                            </select>
                                        </div>

                                    </div>

                                    <div class="form-row">

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateDebutDetachement' +i">Date début détachement</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       placeholder="Date début détachement"
                                                       formControlName="dateDebutDetachement"
                                                       [id]="'dateDebutDetachement' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d5="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateDebutDetachement').invalid && contrat.get('dateDebutDetachement').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d5.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateFinDetachement' +i">Date fin détachement</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       placeholder="Date fin détachement"
                                                       formControlName="dateFinDetachement"
                                                       [id]="'dateFinDetachement' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d6="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateFinDetachement').invalid && contrat.get('dateFinDetachement').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d6.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'matriculeAgence' +i">Matricule agence</label>
                                            <input type="text" class="form-control" placeholder="Matricule agence"
                                                   formControlName="matriculeAgence" [id]="'matriculeAgence' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('matriculeAgence').invalid && contrat.get('matriculeAgence').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'numeroPoste' +i">Numéro de poste</label>
                                            <input type="text" class="form-control" placeholder="Numéro de poste"
                                                   formControlName="numeroPoste" [id]="'numeroPoste' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('numeroPoste').invalid && contrat.get('numeroPoste').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'numen' +i">NUMEN</label>
                                            <input type="text" class="form-control" placeholder="NUMEN"
                                                   formControlName="numen" [id]="'numen' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('numen').invalid && contrat.get('numen').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'cexpat' +i">CEXPAT</label>
                                            <input type="text" class="form-control" placeholder="CEXPAT"
                                                   formControlName="cexpat" [id]="'cexpat' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"/>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'isvl' +i">ISVL</label>
                                            <input type="text" class="form-control" placeholder="ISVL"
                                                   formControlName="isvl" [id]="'isvl' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"/>
                                        </div>


                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'grade' +i">Grade</label>
                                            <input type="text" class="form-control" placeholder="Grade"
                                                   formControlName="grade" [id]="'grade' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('grade').invalid && contrat.get('grade').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'echelon' +i">Echelon</label>
                                            <input type="text" class="form-control" placeholder="echelon"
                                                   formControlName="echelon" [id]="'echelon' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('echelon').invalid && contrat.get('echelon').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'chevron' +i">Chevron</label>
                                            <input type="text" class="form-control" placeholder="Chevron"
                                                   formControlName="chevron" [id]="'chevron' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('chevron').invalid && contrat.get('chevron').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'datePromotion' +i">Date de la promotion en
                                                vigueur</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       placeholder="Date de la promotion en vigueur"
                                                       formControlName="datePromotion" [id]="'datePromotion' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d81="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('datePromotion').invalid && contrat.get('datePromotion').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d81.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </nb-accordion-item-body>

                            </nb-accordion-item>

                            <!-- Mis à disposition  -->
                            <nb-accordion-item
                                    [expanded]="contrat.get('status').value == 'actif' || new[i] ? true : false"
                                    *ngIf="contrat.get('statut').value === 6 && contrat.get('regime').value === 8">

                                <nb-accordion-item-header class="nb-accordion-item-header-override">
                                    Informations contrat
                                </nb-accordion-item-header>

                                <nb-accordion-item-body>

                                    <div class="form-row">
                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'periodeDetachement' +i">Détachement</label>
                                            <input type="text" class="form-control" placeholder="Detachement"
                                                   formControlName="periodeDetachement" [id]="'periodeDetachement' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('periodeDetachement').invalid && contrat.get('periodeDetachement').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateDebutDetachement' +i">Date début détachement</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       formControlName="dateDebutDetachement"
                                                       [id]="'dateDebutDetachement' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d14="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateDebutDetachement').invalid && contrat.get('dateDebutDetachement').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d14.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateFinDetachement' +i">Date fin détachement</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       formControlName="dateFinDetachement"
                                                       [id]="'dateFinDetachement' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d15="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateFinDetachement').invalid && contrat.get('dateFinDetachement').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d15.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'numeroSom' +i">Numéro de SOM</label>
                                            <input type="text" class="form-control" placeholder="Numéro de Som"
                                                   formControlName="numeroSom" [id]="'numeroSom' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('numeroSom').invalid && contrat.get('numeroSom').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'grade' +i">Grade</label>
                                            <input type="text" class="form-control" placeholder="grade"
                                                   formControlName="grade" [id]="'grade' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('grade').invalid && contrat.get('grade').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>
                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'echelon' +i">Echelon</label>
                                            <input type="text" class="form-control" placeholder="echelon"
                                                   formControlName="echelon" [id]="'echelon' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [ngClass]="{'is-invalid': contrat.get('echelon').invalid && contrat.get('echelon').touched}"
                                                   (blur)="logValidationErrors()"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'datePromotion' +i">Date de la promotion en
                                                vigueur</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       placeholder="Date de la promotion en vigueur"
                                                       formControlName="datePromotion" [id]="'datePromotion' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d82="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('datePromotion').invalid && contrat.get('datePromotion').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d82.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </nb-accordion-item-body>

                            </nb-accordion-item>


                            <!-- Recruté droit local -->
                            <nb-accordion-item
                                    [expanded]="contrat.get('status').value == 'actif'  || new[i]? true : false"
                                    *ngIf="contrat.get('statut').value === 3 && contrat.get('regime').value !== 10 && (contrat.get('regime').value === 6 || contrat.get('regime').value === 7 ) ">
                                <nb-accordion-item-header class="nb-accordion-item-header-override">
                                    Informations contrat
                                </nb-accordion-item-header>

                                <nb-accordion-item-body>
                                    <div class="form-row">

                                        <div class="form-group col-md-3">
                                            <label [attr.for]="'contrat' +i">Type Contrat </label>
                                            <select class="form-control" formControlName="contrat" id="'contrat' +i"
                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                    (change)="typeContratChangedHandler($event, i)"
                                                    [ngClass]="{'is-invalid': contrat.get('contrat').invalid && contrat.get('contrat').touched}"
                                                    (blur)="logValidationErrors()">
                                                <option *ngFor="let item of (typeContrats | async)"
                                                        [ngValue]="item.id ">
                                                    {{ item.description }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateCdd' +i">Date CDD</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Date CDD"
                                                       formControlName="dateCdd" [id]="'dateCdd' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d10="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateCdd').invalid && contrat.get('dateCdd').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d10.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'dateFinCdd' +i">Date Fin CDD</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Date CDD"
                                                       formControlName="dateFinCdd" [id]="'dateFinCdd' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d17="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateFinCdd').invalid && contrat.get('dateFinCdd').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d17.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-2" *ngIf="contrat.get('regime').value === 6">
                                            <label [attr.for]="'dateCdi' +i">Date CDI</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Date CDI"
                                                       formControlName="dateCdi" [id]="'dateCdi' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d11="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('dateCdi').invalid && contrat.get('dateCdi').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d11.toggle()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            type="button"></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-row" *ngIf="contrat.get('contrat').value === 5">
                                        <div class="form-group col-md-12">
                                            <div class="form-row">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'grilleRit' +i">Rit</label>
                                                    <select class="form-control" formControlName="grilleRit"
                                                            id="'grilleRit' +i"
                                                            [ngClass]="{'is-invalid': contrat.get('grilleRit').invalid && contrat.get('grilleRit').touched}"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            (blur)="logValidationErrors()"
                                                            (change)="ritChangedHandler($event, i)"
                                                            [attr.disabled]="true">
                                                        <option *ngFor="let item of (grilleRits[i] | async)"
                                                                [ngValue]="item">
                                                            {{ item }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label [attr.for]="'grilleCategorie' +i">Categorie</label>
                                                    <select class="form-control" formControlName="grilleCategorie"
                                                            id="'grilleCategorie' +i"
                                                            [ngClass]="{'is-invalid': contrat.get('grilleCategorie').invalid && contrat.get('grilleCategorie').touched}"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            (blur)="logValidationErrors()"
                                                            (change)="categoryChangedHandler($event, i)">
                                                        <option *ngFor="let item of (grilleCategories[i] | async)"
                                                                [ngValue]="item[0]">
                                                            {{ item[1] }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label [attr.for]="'grilleNiveau' +i">Niveau</label>
                                                    <select class="form-control" formControlName="grilleNiveau"
                                                            id="'grilleNiveau' +i"
                                                            [ngClass]="{'is-invalid': contrat.get('grilleNiveau').invalid && contrat.get('grilleNiveau').touched}"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            (blur)="logValidationErrors()"
                                                            (change)="levelChangedHandler($event, i)"
                                                            (click)="levelChangedHandler($event, i)">
                                                        <option *ngFor="let item of (grilleNiveaus[i] | async)"
                                                                [ngValue]="item[0]">
                                                            {{ item[1] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label [attr.for]="'grilleFonction' +i">Fonction</label>
                                                    <select class="form-control" formControlName="grilleFonction"
                                                            id="'grilleFonction' +i"
                                                            [ngClass]="{'is-invalid': contrat.get('grilleFonction').invalid && contrat.get('grilleFonction').touched}"
                                                            (blur)="logValidationErrors()"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            (change)="functionChangedHandler($event, i)"
                                                            (click)="functionChangedHandler($event, i)">
                                                        <option *ngFor="let item of (grilleFonctions[i] | async)"
                                                                [ngValue]="item[0]">
                                                            {{ item[1] }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'grilleType' +i">Type</label>
                                                    <select class="form-control" formControlName="grilleType"
                                                            id="'grilleType' +i"
                                                            [ngClass]="{'is-invalid': contrat.get('grilleType').invalid && contrat.get('grilleType').touched}"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            (blur)="logValidationErrors()"
                                                            (change)="typeChangedHandler($event, i)"
                                                            (click)="typeChangedHandler($event, i)">
                                                        <option *ngFor="let item of (grilleTypes[i] | async)"
                                                                [ngValue]="item[0]">
                                                            {{ item[1] }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-1">
                                                    <label [attr.for]="'grilleDescription' +i">Grille</label>
                                                    <select class="form-control" formControlName="grilleDescription"
                                                            id="'grilleDescription' +i"
                                                            [ngClass]="{'is-invalid': contrat.get('grilleDescription').invalid && contrat.get('grilleDescription').touched}"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            (blur)="logValidationErrors()"
                                                            (change)="descriptionChangedHandler($event, i)">
                                                        <option *ngFor="let item of (grilleDescriptions[i] | async)"
                                                                [ngValue]="item">
                                                            {{ item }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-1">
                                                    <label [attr.for]="'echelon' +i">Echelon</label>
                                                    <select class="form-control" formControlName="echelon"
                                                            id="'echelon' +i"
                                                            [ngClass]="{'is-invalid': contrat.get('echelon').invalid && contrat.get('echelon').touched}"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            (blur)="logValidationErrors()"
                                                            (change)="echelonChangedHandler($event, i)">
                                                        <option *ngFor="let item of (grilleEchelons[i] | async)"
                                                                [ngValue]="item[0]">
                                                            {{ item[0] }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-1">
                                                    <label [attr.for]="'duree' +i">Durée</label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="duree"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [id]="'duree' +i" [readOnly]="true"/>
                                                </div>

                                                <div class="form-group col-md-1">
                                                    <label [attr.for]="'indiceBrut' +i">Indice Brut</label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="indiceBrut"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [id]="'indiceBrut' +i" [readOnly]="true"/>
                                                    <input type="hidden" class="form-control" placeholder=""
                                                           formControlName="grille"/>
                                                </div>

                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'abbat' +i">Abattement </label>
                                                    <select class="form-control" formControlName="abbat" id="'abbat' +i"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null">
                                                        <option *ngFor="let item of abbatements"
                                                                [ngValue]="item.value ">
                                                            {{ item.viewValue }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'reliquat' +i">Reliquat <sup>(mois)</sup></label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="reliquat"
                                                           [readOnly]="contrat.get('status').value == 'archive'"
                                                           [id]="'reliquat' +i"
                                                           (keyup)="echelonChangedHandler($event, i)"/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label [attr.for]="'datePromotion' +i"> Date de la promotion en
                                                        vigueur</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date de la promotion en vigueur"
                                                               formControlName="datePromotion" [id]="'datePromotion' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d83="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [ngClass]="{'is-invalid': contrat.get('datePromotion').required && contrat.get('datePromotion').touched}"
                                                               (blur)="logValidationErrors()"
                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d83.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label [attr.for]="'typeAvancement' +i">Type d'avancement </label>
                                                    <select class="form-control" formControlName="typeAvancement"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                            id="'typeAvancement' +i">
                                                        <option *ngFor="let item of this.globalObject.typeAvancement"
                                                                [ngValue]="item.value ">
                                                            {{ item.viewValue }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-2">
                                                    <label [attr.for]="'promotionMoisGagnes' +i">Nombre de mois
                                                        gagnés</label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="promotionMoisGagnes"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [id]="'promotionMoisGagnes' +i"
                                                           (blur)="createAvancementObject(i)"
                                                           (keyup)="createAvancementObject(i)"
                                                    />
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label [attr.for]="'dateAvancement' +i">Date de la promotion
                                                        accélérée</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                               placeholder="Date de la promotion accélérée"
                                                               formControlName="dateAvancement"
                                                               [id]="'dateAvancement' +i"
                                                               [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                               container="body" ngbDatepicker #d93="ngbDatepicker"
                                                               placement="top"
                                                               [navigation]="true" [outsideDays]="true"
                                                               [showWeekNumbers]="true"
                                                               [ngClass]="{'is-invalid': contrat.get('dateAvancement').required && contrat.get('dateAvancement').touched}"
                                                               (blur)="logValidationErrors()"
                                                        />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                    (click)="d93.toggle()"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-12">
                                                    <label [attr.for]="'dateProchainePromotion' +i">Date prochaine
                                                        promotion</label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="dateProchainePromotion" readonly="true"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [id]="'observations' +i"/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-7" *ngIf="showAnciennete">
                                                    <label [attr.for]="'observationProchainePrimeAnciennete' +i">Observation
                                                        prochaine prime d'anciennete</label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="observationProchainePrimeAnciennete"
                                                           readonly="true"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [id]="'observationProchainePrimeAnciennete' +i"/>
                                                </div>
                                                <div class="form-group col-md-3" *ngIf="showAnciennete">
                                                    <label [attr.for]="'dateProchainePrimeAnciennete' +i">Date prochaine
                                                        prime d'anciennete</label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="dateProchainePrimeAnciennete"
                                                           readonly="true"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [id]="'dateProchainePrimeAnciennete' +i"/>
                                                </div>
                                                <div class="form-group col-md-2" *ngIf="showAnciennete">
                                                    <label [attr.for]="'prochainePrimeAnciennete' +i">Prochaine prime
                                                        d'anciennete</label>
                                                    <input type="text" class="form-control" placeholder=""
                                                           formControlName="prochainePrimeAnciennete" readonly="true"
                                                           [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                           [id]="'prochainePrimeAnciennete' +i"/>
                                                </div>
                                            </div>
                                            <div class="form-row">

                                            </div>
                                            <div class="form-row">

                                            </div>
                                        </div>


                                    </div>

                                    <div class="form-row" *ngIf="contrat.get('contrat').value !== 5">

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'echelon' +i">Echelon</label>
                                            <input type="text" class="form-control" placeholder="echelon"
                                                   formControlName="echelon" [id]="'echelon' +i"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"/>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label [attr.for]="'datePromotion' +i">Date de la promotion en
                                                vigueur</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       placeholder="Date de la promotion en vigueur"
                                                       formControlName="datePromotion" [id]="'datePromotion' +i"
                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                       container="body" ngbDatepicker #d84="ngbDatepicker"
                                                       placement="top"
                                                       [navigation]="true" [outsideDays]="true" [showWeekNumbers]="true"
                                                       [ngClass]="{'is-invalid': contrat.get('datePromotion').invalid && contrat.get('datePromotion').touched}"
                                                       (blur)="logValidationErrors()"
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar"
                                                            (click)="d84.toggle()"
                                                            type="button"
                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label [attr.for]="'reliquat' +i">Reliquat <sup>(mois)</sup></label>
                                            <input type="text" class="form-control" placeholder=""
                                                   formControlName="reliquat"
                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                   [id]="'reliquat' +i"/>
                                        </div>

                                    </div>

                                </nb-accordion-item-body>

                            </nb-accordion-item>


                            <!-- Quotite annuelle-->

                            <nb-accordion-item
                                    [expanded]="contrat.get('status').value == 'actif'  || new[i]? true : false"
                                    *ngIf="contrat.get('statut').value === 3 && contrat.get('regime').value !== 10 && (contrat.get('regime').value === 6 || contrat.get('regime').value === 7 ) ">
                                <nb-accordion-item-header class="nb-accordion-item-header-override">
                                    Quotité de travail année en cours
                                </nb-accordion-item-header>

                                <nb-accordion-item-body>

                                    <div formArrayName="quotiteHebdomadaires"
                                         *ngFor="let quotiteHebdomadaire of getQuotiteHebdomadaires(i).controls; let j=index">
                                        <div [formGroupName]="j">
                                            <hr *ngIf="j > 0"/>


                                            <div class="form-row">

                                                <div class="col-md-10">

                                                    <div class="form-row">


                                                        <div class="form-group col-md-3">
                                                            <label class="control-label"
                                                                   [attr.for]="'dateDebutQuotiteHebdomadaire_' +j">Date
                                                                de début</label>
                                                            <div class="input-group">
                                                                <input class="form-control" placeholder="dd/mm/yyyy"
                                                                       container="body" formControlName="dateDebut"
                                                                       [id]="'dateDebutQuotiteHebdomadaire_' +j"
                                                                       placement="top"
                                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                       ngbDatepicker
                                                                       #dateDebutQuotiteHebdomadaire="ngbDatepicker"
                                                                       [navigation]="true" [outsideDays]="true"
                                                                       [showWeekNumbers]="true"
                                                                       [ngClass]="{'is-invalid': quotiteHebdomadaire.get('dateDebut').invalid && quotiteHebdomadaire.get('dateDebut').touched}"
                                                                       (blur)="logValidationErrors()"
                                                                />
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary calendar"
                                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                            (click)="dateDebutQuotiteHebdomadaire.toggle()"
                                                                            type="button"></button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group col-md-3">
                                                            <label class="control-label"
                                                                   [attr.for]="'dateFinQuotiteHebdomadaire_' +j">Date de
                                                                fin</label>
                                                            <div class="input-group">
                                                                <input class="form-control" placeholder="dd/mm/yyyy"
                                                                       container="body" formControlName="dateFin"
                                                                       [id]="'dateFinQuotiteHebdomadaire_' +j"
                                                                       placement="top"
                                                                       [readonly]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                       ngbDatepicker
                                                                       #dateFinQuotiteHebdomadaire="ngbDatepicker"
                                                                       [navigation]="true" [outsideDays]="true"
                                                                       [showWeekNumbers]="true"
                                                                       [ngClass]="{'is-invalid': quotiteHebdomadaire.get('dateFin').invalid && quotiteHebdomadaire.get('dateFin').touched}"
                                                                       (blur)="logValidationErrors()"
                                                                />
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary calendar"
                                                                            [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                            (click)="dateFinQuotiteHebdomadaire.toggle()"
                                                                            type="button"></button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="form-group col-md-2">
                                                            <label [attr.for]="'quotite_' +j">Quotité</label>
                                                            <input type="hidden" class="form-control"
                                                                   formControlName="id"/>
                                                            <input type="text" class="form-control"
                                                                   placeholder="Quotite" formControlName="quotite"
                                                                   [id]="'quotite_' +j"
                                                                   [ngClass]="{'is-invalid': quotiteHebdomadaire.get('quotite').invalid && quotiteHebdomadaire.get('quotite').touched}"
                                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                   (blur)="logValidationErrors()"/>
                                                            <div class="invalid-feedback"
                                                                 *ngIf="quotiteHebdomadaire.get('quotite').hasError('quotiteGreaterThanBase')">
                                                                La quotité ne peut pas être supérieure à la base de
                                                                quotité.
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label [attr.for]="'baseQuotite_' +j">Total Heures</label>
                                                            <input type="text" class="form-control"
                                                                   placeholder="Total Heures"
                                                                   formControlName="baseQuotite"
                                                                   [id]="'baseQuotite_' +j"
                                                                   [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                   [ngClass]="{'is-invalid': quotiteHebdomadaire.get('baseQuotite').invalid && quotiteHebdomadaire.get('baseQuotite').touched}"
                                                                   (blur)="logValidationErrors()"/>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label [attr.for]="'anneeScolaire_' +j">Année
                                                                Scolaire</label>

                                                            <select class="form-control" fullWidth
                                                                    formControlName="anneeScolaire"
                                                                    [id]="'anneeScolaire_' +j"
                                                                    [attr.disabled]="contrat.get('status').value == 'archive' ? 'disabled' : null"
                                                                    [ngClass]="{'is-invalid': quotiteHebdomadaire.get('anneeScolaire').invalid && quotiteHebdomadaire.get('anneeScolaire').touched}"
                                                                    (blur)="logValidationErrors()">
                                                                <option *ngFor="let item of this.globalObject.anneeScolaire"
                                                                        [ngValue]="item.value ">
                                                                    {{ item.viewValue }}
                                                                </option>
                                                            </select>
                                                        </div>

                                                    </div>


                                                </div>

                                                <div class="col-md-2" style="text-align: center">
                                                    <button nbButton status="danger" style='margin: 26px 0 0 0;'
                                                            type="button" (click)="deleteQuotiteHebdomadaire(i, j)">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <button nbButton status="success" type="button"
                                            (click)="addQuotiteHebdomadaireButtonClick(i)"
                                            [disabled]="contrat.get('quotiteHebdomadaires').invalid || contrat.get('status').value == 'archive' "><i
                                            class="fas fa-plus"></i></button>


                                </nb-accordion-item-body>

                            </nb-accordion-item>


                        </nb-accordion>

                    </nb-card-body>

                </nb-card>
            </div>
        </div>
    </div>


    <div class="form-row" style="margin-top: 12px" *ngIf="editForm['controls'].contrats['controls'].length>0">
        <div class="form-group col-md-12" *nbIsGranted="['create', 'contrat']">
            <button nbButton status="primary" type="submit" [disabled]="editForm.invalid || isSubmitting">Enregistrer</button>
        </div>
    </div>
</form>
