import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PersonnelService} from '../../../lyautey/services/personnel.service';
import {AppAuthGuard} from '../../../lyautey/services/app-auth-guard.service';
import {NbAclService} from "@nebular/security";
import {PersonneModel} from "../../../lyautey/model/personnel.model";
import {ContratService} from "../../../lyautey/services/contrat.service";
import {map} from "rxjs/operators";
import {forkJoin} from "rxjs";

@Component({
    selector: 'ngx-personne',
    templateUrl: './personne.component.html',
    styleUrls: ['./personne.component.scss'],
})
export class PersonneComponent implements OnInit {

    tabs: any[] = [];
    matricule = '';

    constructor(private route: ActivatedRoute,
                private personnelService: PersonnelService,
                private contratService: ContratService,
                public appAuthGuard: AppAuthGuard,
                public nbAclService: NbAclService) {

    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const id: number = Number(params['id']);
            if (id) {
                this.personnelService.getPersonnel(id)
                    .subscribe(
                        (personneModel: PersonneModel) => {
                            this.personnelService.getGenre(id)
                                .subscribe((data: any) => {
                                    this.matricule = `${data.description} ${personneModel.nomUsuel} ${personneModel.prenom} [${personneModel.id}]`;


                                });
                        },
                        (err: any) => {
                        },
                    );
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'personnel')) {
                    this.tabs.push({
                        title: 'Fiche Personnel',
                        route: `/pages/personnel/personne/${id}/fiche`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'contrat')) {
                    this.tabs.push({
                        title: 'Contrat',
                        route: `/pages/personnel/personne/${id}/contrat`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'listing', 'document')) {
                    this.tabs.push({
                        title: 'Documents',
                        route: `/pages/personnel/personne/${id}/documents`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'listing', 'formation')) {
                    this.tabs.push({
                        title: 'Formation & Stage',
                        route: `/pages/personnel/personne/${id}/formation`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'listing', 'evaluation')) {
                    this.tabs.push({
                        title: 'Evaluation',
                        route: `/pages/personnel/personne/${id}/inspection`,
                    });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'cte')) {
                    this.personnelService.getNationalites(id)
                        .subscribe((data: any) => {
                            const countryMorocco = data._embedded.countries.filter(function (country) {
                                return country.alpha2 === 'MA';
                            });
                            if (countryMorocco.length === 0) {
                                this.tabs.push({title: 'CTE', route: `/pages/personnel/personne/${id}/cte`});
                            }
                        });
                }
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'personnel')) {
                    this.tabs.push({
                            title: 'Visite Médicale',
                            route: `/pages/personnel/personne/${id}/visite-medicale`,
                        },
                        {
                            title: 'Accident de Travail',
                            route: `/pages/personnel/personne/${id}/accident-travail`,
                        },
                        {
                            title: 'Avancement',
                            route: `/pages/personnel/personne/${id}/avancement`,
                        },
                    );
                }
                this.contratService.getByPersonId(id).subscribe((data: any) => {
                    const observables = [];
                    const suspensionObservables = [];

                    if (data._embedded.contrats.length > 0) {
                        data._embedded.contrats.forEach((contrat) => {
                            // Fetch the "statut" using HATEOAS link
                            const statutObservable = this.contratService
                                .getByUrl(contrat._links.typeStatut.href)
                                .pipe(map((statutData: any) => statutData.description));

                            observables.push(statutObservable);

                            // Fetch suspensions if the "suspensions" link exists
                            if (contrat._links?.suspensions?.href) {
                                const suspensionObservable = this.contratService
                                    .getByUrl(contrat._links.suspensions.href)
                                    .pipe(map((suspensionData: any) => suspensionData._embedded?.suspensions?.length > 0));
                                suspensionObservables.push(suspensionObservable);
                            }
                        });

                        // Wait for both "statut" and "suspension" observables
                        forkJoin([forkJoin(observables), forkJoin(suspensionObservables)]).subscribe(
                            ([statutResults, suspensionResults]) => {
                                console.log('statutResults', statutResults);
                                console.log('suspensionResults', suspensionResults);

                                // Check if any contract has the required statut
                                const hasRecruteDroitLocal = statutResults.some((statut) => statut === 'Recruté droit local');
                                const hasSuspensions = suspensionResults.some((suspensionExists) => suspensionExists === true);

                                // Add the tab if conditions are met
                                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'view', 'personnel') && hasRecruteDroitLocal && hasSuspensions) {
                                    this.tabs.push({
                                        title: 'Suspension',
                                        route: `/pages/personnel/personne/${id}/suspension`,
                                    });
                                }
                            }
                        );
                    }
                });



            } else {
                if (this.nbAclService.can(this.appAuthGuard.activeGroup, 'create', 'personnel')) {
                    this.tabs.push({
                        title: 'Fiche Personnel',
                        route: `/pages/personnel/personne/fiche`,
                    });
                }
            }
        });
    }

}
